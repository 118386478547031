.App-Header {
  z-index: 900;
  position: fixed !important;
  bottom: 10px;
  right: 10px;
}

.App-logo {
  height: 40px;
}

.page {
  margin-top: 60px;
  max-width: 75vw;
  margin-left: auto;
  margin-right: auto;
}

.access-denied {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
