.Zoom {
  display: none;
}

@media (min-width: 992px) {
  .Zoom {
    display: block;
    position: fixed;
    bottom: 85px;
    right: 50px;
    height: 180px;
    z-index: 990;
  }
}
