.TocContent {

}

button {
  display: block;
  cursor: pointer;
  outline: none;
  border: none;
  padding-left: 0;
  padding-right: 0;
  font-family: 'Helvetica Neue', sans-serif;
  background-color: transparent;
}

.TocLink-row {
  display: flex;
  justify-content: space-between;
}

.TocLink-link {
  font-size: .9rem;
  padding: 7px 10px;
  text-align: left;
}

.TocLink-link:hover {
  margin-left: 20px;
  transition-duration: 0.6s;
}

.TocLink-subchapters .level-1 button {
  text-indent: 20px;
}

.TocLink-subchapters .level-2 button {
  text-indent: 40px;
}

.TocContent-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.TocContent-summary {
  font-size: 1.25em;
  font-weight: bold;
  text-transform: uppercase;
}

.TocContent-bookmarks,
.TocContent-bookmarks img {
  height: 30px
}

/* Custom styles for Menu */

/* Position and sizing of burger button */
.bm-burger-button {
  display: none;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  display: none;
  /* height: 24px; */
  /* width: 24px; */
}

/* Sidebar wrapper styles */
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  font-size: 1.15em;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  outline: none;
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
