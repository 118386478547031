footer {
  margin-top: 60px;
}

.links {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  padding-left: 0;
}

.links  a {
  color: gray;
  text-decoration: none;
}

