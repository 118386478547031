.BookmarkPage {
  position: fixed;
  top: 10px;
  left: calc((100vw - 23px) / 2);
  z-index: 900;
  font-size: 1rem;
  text-align: center;
}

.BookmarkPage svg {
  height: 30px;
}
