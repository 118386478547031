.LoadingView {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.LoadingView-background {
  flex: 0 0 37vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 17px;
  height: calc(100% - 100px);
}

.LoadingView-loading {
  font-size: 1.5rem;
  text-align: center;
  line-height: 35px;
}
