.EmbedMedia {

}

.EmbedMedia-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,.8);
  z-index: 990;
}

.EmbedMedia-modal {
  position: absolute;
  top: 75px;
  left: 75px;
  right: 75px;
  bottom: 75px;
  background-color: black;
  z-index: 990;
}

.EmbedMedia-modal.embed-audio {
  background: url(../../images/audio-bg.jpg) no-repeat center fixed;
  background-size: cover;
}

.EmbedMedia-close {
  position: absolute;
  top: 2px;
  right: 10px;
  font-size: 2rem;
  color: white;
  z-index: 999;
  cursor: pointer;
  text-shadow: 1px 1px 1px black;
}

.EmbedMedia-modal video {
  outline: none;
}
