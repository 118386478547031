/* Custom class in ebook */
.EbookReader {
  width: 100%;
  height: 100vh;
}

.App-Footer {
  padding: 5px 15px;
  z-index: 900;
  position: fixed !important;
  bottom: 12px;
  left: 12px;
  cursor: pointer;
}

.App-term {
  width: 50px;
}
