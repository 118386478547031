.Navigation button {
  outline: none;
  border: none;
  background: none;
  position: fixed;
  top: 50%;
  margin-top: -32px;
  font-size: 64px;
  padding: 0px 10px;
  font-family: arial, sans-serif;
  cursor: pointer;
  user-select: none;
  appearance: none;
  font-weight: normal;
  z-index: 900;
}

.Navigation button.prev {
  left: 1px;
}

.Navigation button.next {
  right: 1px;
}


