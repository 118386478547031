.Hamburger {
  text-transform: uppercase;
  font-size: .9rem;
  padding: 5px 15px;
  z-index: 900;
  position: fixed !important;
  top: 12px;
  left: 12px;
  cursor: pointer;
}

.Hamburger svg {
  height: 25px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;
}

